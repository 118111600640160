var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { backgroundColor, backgroundImage, borderColor, color, width } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import { OutlineSelect } from '../../common/components/Select';
import { getUrlPrefix } from '../../common/utils/url';
import arrowDown from './assets/arrow-down-white.svg';
import { trackLanguageChange, trackLanguageClick } from './tracking';
var LanguageSelect = styled(OutlineSelect)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), width, backgroundColor, backgroundImage, color, borderColor);
var languageSelectStyles = {
    width: 1,
    backgroundColor: 'transparent',
    backgroundImage: "url('".concat(arrowDown, "')"),
    color: 'sys.neutral.border.inverted',
    borderColor: 'sys.neutral.border.inverted',
};
var LanguageSwitch = function (_a) {
    var _b = _a.languages, languages = _b === void 0 ? [] : _b;
    var _c = __read(useState(function () {
        return languages.find(function (_a) {
            var isCurrent = _a.isCurrent;
            return isCurrent;
        });
    }), 2), currentLanguage = _c[0], setCurrentLanguage = _c[1];
    if (!languages || (languages === null || languages === void 0 ? void 0 : languages.length) < 2) {
        return null;
    }
    var selectHandler = function (event) {
        var newLanguage = languages.find(function (_a) {
            var language = _a.language;
            return language === event.target.value;
        });
        trackLanguageChange(newLanguage.language);
        setCurrentLanguage(newLanguage);
        window.location.href = getUrlPrefix(newLanguage.pathPrefix);
    };
    return (React.createElement(Flex, { pb: "sp_16", px: ['sp_16', null, 'sp_40', 'sp_64'], backgroundColor: "sys.neutral.background.strong" },
        React.createElement(LanguageSelect, __assign({ options: languages.map(function (_a) {
                var language = _a.language;
                return language;
            }), value: currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.language, onClick: trackLanguageClick, onSelect: selectHandler }, languageSelectStyles))));
};
export default LanguageSwitch;
var templateObject_1;
