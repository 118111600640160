var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment, useDeferredValue } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { boxShadow, color, compose, display, flexDirection, height, left, position, right, backgroundColor as styledBackgroundColor, width as styledWidth, top, zIndex, } from 'styled-system';
import { BACKDROP_Z_INDEX, MODAL_Z_INDEX } from '../constants/z-index';
import { useHiddenBodyOverflow } from '../hooks/scroll';
import { useLocalization } from '../providers/LocaleProvider';
import { overflowY, themeWidth } from '../theme/system-utilities';
import LazyFocusLock from './LazyFocusLock';
export var DRAWER_WIDTH = ['100%', '448px', '496px', '600px'];
var DrawerContainer = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  transition-property: transform, width;\n  transition-duration: 0.16s;\n  transition-timing-function: ", ";\n  transform: ", ";\n  ", ";\n"], ["\n  ", "\n  transition-property: transform, width;\n  transition-duration: 0.16s;\n  transition-timing-function: ", ";\n  transform: ", ";\n  ", ";\n"])), compose(position, top, right, themeWidth, height, styledBackgroundColor, boxShadow, zIndex, display, flexDirection, overflowY, color), function (props) { return (props.isOpen ? 'ease-out' : 'ease-in'); }, function (props) {
    return props.isOpen
        ? 'translate3d(0, 0, 0)'
        : props.isLeftOpener
            ? 'translate3d(-100%, 0, 0)'
            : 'translate3d(100%, 0, 0)';
}, function (props) {
    return props.isLeftOpener && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      left: 0;\n      box-shadow: 0 0 4px 0 rgba(44, 54, 56, 0.3);\n    "], ["\n      left: 0;\n      box-shadow: 0 0 4px 0 rgba(44, 54, 56, 0.3);\n    "])));
});
var drawerContainerStyles = {
    zIndex: MODAL_Z_INDEX,
    position: 'fixed',
    top: 0,
    right: 0,
    themeWidth: DRAWER_WIDTH,
    height: '100%',
    backgroundColor: 'sys.neutral.background.default',
    overflowY: 'auto',
};
var SIDE_NAV_STYLES = {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    as: 'nav',
};
var DrawerBackdrop = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n\n  transition: opacity 0.16s linear;\n  opacity: ", ";\n  pointer-events: ", ";\n"], ["\n  ", "\n\n  transition: opacity 0.16s linear;\n  opacity: ", ";\n  pointer-events: ", ";\n"])), compose(position, top, left, zIndex, styledWidth, height, styledBackgroundColor), function (props) { return (props.isOpen ? 1 : 0); }, function (props) { return (props.isOpen ? 'auto' : 'none'); });
export var drawerBackdropStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: BACKDROP_Z_INDEX,
    width: '100%',
    height: '100%',
    backgroundColor: 'overlay.default',
};
var getDynamicStyles = function (_a) {
    var isOpen = _a.isOpen, width = _a.width, isSideNav = _a.isSideNav, backgroundColor = _a.backgroundColor;
    return __assign(__assign(__assign({ boxShadow: isOpen ? '-4px 2px 6px 0 rgba(0, 0, 0, 0.15)' : 'none' }, (width && { themeWidth: width })), (isSideNav && SIDE_NAV_STYLES)), (backgroundColor && { backgroundColor: backgroundColor }));
};
var Drawer = function (_a) {
    var _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, _c = _a.isSideNav, isSideNav = _c === void 0 ? false : _c, _d = _a.isLeftOpener, isLeftOpener = _d === void 0 ? false : _d, children = _a.children, ariaLabelledby = _a.ariaLabelledby, onBackdropClick = _a.onBackdropClick, width = _a.width, containerRef = _a.containerRef, onKeyDown = _a.onKeyDown, backgroundColor = _a.backgroundColor;
    // We use deferred value to improve rendering performance of the drawer.
    // Drawer animation is performed immediately, but the content appears in no blocking-manner.
    // This solution affects INP (Interaction to Next Pain) metric significantly.
    var isDeferredOpen = useDeferredValue(isOpen);
    var t = useLocalization().t;
    useHiddenBodyOverflow(isOpen);
    return (React.createElement(Fragment, null,
        React.createElement(LazyFocusLock, { returnFocus: { preventScroll: false }, disabled: !isOpen },
            React.createElement(DrawerContainer, __assign({}, drawerContainerStyles, { isOpen: isOpen, isLeftOpener: isLeftOpener || isSideNav, "aria-hidden": !isOpen, role: "dialog", "aria-modal": "true", "data-testid": "drawer-container" }, (isLeftOpener && !isOpen && { themeWidth: 320 }), getDynamicStyles({ isOpen: isOpen, width: width, isSideNav: isSideNav, backgroundColor: backgroundColor }), (ariaLabelledby
                ? { 'aria-labelledby': ariaLabelledby, 'data-autofocus': true }
                : { 'aria-label': t('h24_drawer_aria_label') }), (containerRef && { ref: containerRef }), (onKeyDown && { onKeyDown: onKeyDown })), isDeferredOpen ? children : null)),
        React.createElement(DrawerBackdrop, __assign({}, drawerBackdropStyles, { isOpen: isOpen, onClick: onBackdropClick, "aria-hidden": !isOpen, "data-testid": "drawer-backdrop" }))));
};
export default Drawer;
var templateObject_1, templateObject_2, templateObject_3;
