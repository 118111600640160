var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useEffect, useState, useTransition } from 'react';
import { EventBusEvent } from '../../../common/enums/EventBus';
import { useMatchesViewport } from '../../../common/hooks/media';
import { useSubscriptionCallback } from '../../../common/hooks/subscription';
import { getCascadingNavigationData, getNavigationData, sortNavigation } from '../utils';
var useCurrentNavigation = function (initialNavigation, isCascading) {
    var _a = __read(useTransition(), 2), startTransition = _a[1];
    // Currently selected navigation items
    var _b = __read(useState([]), 2), currentNavigation = _b[0], setCurrentNavigation = _b[1];
    // Initially selected item (from Top navigation)
    var _c = __read(useState(null), 2), selectedItem = _c[0], setSelectedItem = _c[1];
    var clickHandler = useCallback(function (level) { return function (event) {
        var itemId = Number.parseInt(event.currentTarget.dataset.itemId, 10);
        // We use transition to optimize rendering performance - it affects INP significantly.
        startTransition(function () {
            setCurrentNavigation(__spreadArray(__spreadArray([], __read(currentNavigation.slice(0, level)), false), [itemId], false));
        });
    }; }, [currentNavigation]);
    var navigation = isCascading
        ? getCascadingNavigationData({
            currentNavigation: currentNavigation,
            initialNavigation: initialNavigation,
            selectedItem: selectedItem,
        })
        : getNavigationData(currentNavigation, initialNavigation);
    return {
        currentNavigation: currentNavigation,
        setCurrentNavigation: setCurrentNavigation,
        clickHandler: clickHandler,
        selectedItem: selectedItem,
        setSelectedItem: setSelectedItem,
        navigation: navigation,
    };
};
var useHandlers = function (_a) {
    var type = _a.type, isCascading = _a.isCascading, setCurrentNavigation = _a.setCurrentNavigation, setSelectedItem = _a.setSelectedItem, initialNavigation = _a.initialNavigation;
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    useSubscriptionCallback(EventBusEvent.ToggleSideNav, function (data) {
        if (data.type && data.type !== type)
            return;
        setIsOpen(data.isOpen);
        if (data.selectedItem) {
            var index = initialNavigation.children.findIndex(function (item) { return item.name === data.selectedItem; });
            setSelectedItem(index);
        }
    });
    var closeHandler = function () {
        if (isCascading) {
            setCurrentNavigation([]);
            setSelectedItem(null);
        }
        setIsOpen(false);
    };
    return { isOpen: isOpen, closeHandler: closeHandler };
};
export var useNavigation = function (unsortedNavigation, type) {
    var matches = useMatchesViewport().matches;
    var isCascading = matches.matchesDesktop;
    var initialNavigation = __assign(__assign({}, unsortedNavigation), { children: sortNavigation(unsortedNavigation.children, type) });
    var _a = useCurrentNavigation(initialNavigation, isCascading), currentNavigation = _a.currentNavigation, setCurrentNavigation = _a.setCurrentNavigation, clickHandler = _a.clickHandler, selectedItem = _a.selectedItem, setSelectedItem = _a.setSelectedItem, navigation = _a.navigation;
    var _b = useHandlers({
        type: type,
        isCascading: isCascading,
        setCurrentNavigation: setCurrentNavigation,
        setSelectedItem: setSelectedItem,
        initialNavigation: initialNavigation,
    }), isOpen = _b.isOpen, closeHandler = _b.closeHandler;
    useEffect(function () {
        if (!isOpen) {
            setCurrentNavigation([]);
            setSelectedItem(null);
        }
    }, [matches.matchesDesktop]);
    var navItemParent = selectedItem !== null ? initialNavigation.children[selectedItem].name : initialNavigation.name;
    return {
        isOpen: isOpen,
        currentNavigation: currentNavigation,
        closeHandler: closeHandler,
        setCurrentNavigation: setCurrentNavigation,
        selectedItem: selectedItem,
        setSelectedItem: setSelectedItem,
        navigation: navigation,
        clickHandler: clickHandler,
        isCascading: isCascading,
        initialNavigation: initialNavigation,
        navItemParent: navItemParent,
    };
};
