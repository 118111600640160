var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { HOTJAR_SUPPRESS_ATTRS } from '../../common/constants/hotjar';
import { SideNavType } from '../../common/utils/navigation';
import EntryPointIcon from './common/EntryPointIcon';
import Icon from './common/Icon';
var CASCADING_DRAWER_WIDTH = [
    // Width for L, XL
    [344, 416], // 1 level
    [664, 784], // 2 levels
    [984, 1152], // 3 levels
];
var MOBILE_DRAWER_WIDTH = 320;
export var getUspEntryPoints = function (uspEntryPoints) {
    return uspEntryPoints
        .map(function (_a) {
        var _b;
        var icon = _a.icon, label = _a.label, href = _a.labelUrl.fullUrl;
        return !!label &&
            !!href && {
            label: label,
            href: href,
            icon: React.createElement(EntryPointIcon, { src: icon.url, alt: "".concat((_b = icon.alt) !== null && _b !== void 0 ? _b : label, " icon") }),
        };
    })
        .filter(Boolean);
};
export var getNavigationItems = function (_a) {
    var l10n = _a.l10n, customer = _a.customer;
    var t = l10n.t, p = l10n.p, r = l10n.r;
    var navigation = [
        (customer === null || customer === void 0 ? void 0 : customer.isLoggedIn)
            ? __assign(__assign({ label: r(t('h24_not_your_account'), {
                    username: customer.firstName,
                }), href: p.t('h24_logout_url') }, HOTJAR_SUPPRESS_ATTRS), { icon: React.createElement(Icon, { name: "logout" }) }) : {
            label: t('h24_register'),
            href: p.t('h24_login_page_url'),
            icon: React.createElement(Icon, { name: "login" }),
        },
    ];
    navigation = __spreadArray(__spreadArray([], __read(navigation), false), [
        {
            label: t('h24_my_account'),
            href: p.t('h24_my_overview_url'),
            icon: React.createElement(Icon, { name: "myAccount" }),
        },
    ], false);
    return navigation;
};
export var getCascadingNavigationData = function (_a) {
    var currentNavigation = _a.currentNavigation, initialNavigation = _a.initialNavigation, selectedItem = _a.selectedItem;
    if (selectedItem !== null) {
        return __spreadArray([
            initialNavigation.children[selectedItem]
        ], __read(currentNavigation.map(function (item) { return initialNavigation.children[selectedItem].children[item]; })), false);
    }
    return __spreadArray([
        initialNavigation
    ], __read(currentNavigation.map(function (item, level, map) {
        return level === 0
            ? initialNavigation.children[item]
            : initialNavigation.children[map[level - 1]].children[item];
    })), false);
};
export var getNavigationData = function (currentNavigation, initialNavigation) { return currentNavigation.reduce(function (data, next) { return data.children[next]; }, initialNavigation); };
var sortByPrimary = function (items) {
    if (items === void 0) { items = []; }
    if (items.length <= 2) {
        return items;
    }
    var _a = __read(items), first = _a[0], otherItems = _a.slice(1);
    return __spreadArray([
        first
    ], __read(otherItems.sort(function (a, b) {
        if (a.isPrimary && !b.isPrimary) {
            return -1; // "a" comes before "b"
        }
        else if (!a.isPrimary && b.isPrimary) {
            return 1; // "b" comes before "a"
        }
        else {
            return 0; // no sorting
        }
    })), false);
};
var filterEmptyPromoImgV2 = function (item) {
    if ('promoImgV2' in item) {
        var _a = item.promoImgV2 || {}, x1 = _a.x1, ctaText = _a.ctaText;
        // Remove empty promoImgV2 items from the list
        return item.promoImgV2 && x1 && ctaText;
    }
    return true;
};
export var sortNavigation = function (items, type) {
    if (items === void 0) { items = []; }
    if (type === void 0) { type = SideNavType.Global; }
    if (type === SideNavType.ShopInShop) {
        return items;
    }
    // sort excluding first item
    var sortedList = sortByPrimary(items)
        .filter(filterEmptyPromoImgV2)
        .map(function (item) { return (__assign(__assign({}, item), { children: sortNavigation(item.children) })); });
    // ignore if the list has only the promoImg
    if (sortedList.length === 1 && 'promoImgV2' in sortedList[0]) {
        return null;
    }
    return sortedList;
};
export var getSideNavDrawerContainerStyles = function (level, navLength) { return (__assign(__assign(__assign({}, (level === 0 && { pl: [null, null, 'sp_32', 'sp_48'] })), (level === navLength - 1 && {
    pr: [null, null, 'sp_32', 'sp_48'],
})), (navLength === 3 && {
    // 3 columns on L (decrease width)
    minWidth: [null, null, 280, 320],
}))); };
export var geSideNavDrawerWrapperStyles = function (level, selectedItem) { return (__assign({}, ((level !== 0 || selectedItem != null) && {
    pb: ['sp_16', null, 'sp_20', 'sp_24'],
}))); };
export var getSideDrawerWidth = function (isOpen, isCascading, currentLevel) {
    if (isOpen && isCascading) {
        return __spreadArray([null, null], __read(CASCADING_DRAWER_WIDTH[currentLevel]), false);
    }
    return MOBILE_DRAWER_WIDTH;
};
